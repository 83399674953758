.reseau-container {
  width:100%;
  max-width: 1090px;
  margin-top: 40px;

  &.--padding {
    padding: 0 40px;
  }

  @include mobile {
    margin-top: 20px;

    &.--padding {
      padding: 0 20px;
    }
  }

  .reseau-scontainer {
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    &.-no-padding {
      padding: 0;
    }
    &.-information{
      width:100%
    }
    

  }

}


._reseau_container {
  padding: 20px;
  @include tablette {
    padding: 0px;
  }

  // ._reseau_mb_title {
  //   display: none;
  //   @include fs(14);
  //   font-weight: $semibold;
  //   color: $colorTextBlue;
  //   // margin-bottom: 15px;

  //   @include tablette {
  //     display: block;
  //   }
  // }

  ._reseau_header {
    display: grid;
    grid-template-columns: 60px 2fr 3fr 3fr 3fr 1fr 1fr 10px;
    background-color: #FAFAFB;
    border-radius: 10px;
    padding: 7px;


    @include tablette {
      display: none;
    }

    span {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      @include fs(11);
      font-weight: $semibold;
      color: $colorTextBlue;
    }

  }

  ._reseau_list {
    padding: 20px 0;

    @include tablette {
      padding: 0px;
    }

    ._reseau {
      display: grid;
      grid-template-columns:1fr 1.7fr 3fr 3fr 3fr 2fr ;
      border-radius: 5px;
      padding: 7px;

      transition: $transition;
      
      @include tablette {
        background-color: #FFF;
        margin-top: 15px;
        border-radius: 10px;
        display: block;
        padding: 20px;
      }
      &:hover {
        box-shadow: 2px 2px 10px #CCC;
        transform: scale(1.01);
      }
      
      .-succes{
        color:$colorSuccess
      }
      .-failed{
        color:$colorError
      }
      .-waiting{
        color: $colorWaiting;
      }

      div {
        @include fs(12);
        padding: 10px;
        display: flex;
        align-items: center;
        color: #000;

        &::before {
          content: attr(data-label);
          margin-right: 5px;
          color: #A5A5A5;
          display: none;

          @include tablette {
            display: block;
          }
        }

        @include tablette {
          @include fs(14);
          padding: 0px;
          margin-bottom: 10px;
        }
      }

      ._picto {
        justify-content: center;

        @include tablette {
          display: none;
        }

        a {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include fs(14);
          font-weight: $semibold;
          color: #FFF;
          position: relative;
          overflow: hidden;
          box-shadow: 1px 1px 5px #CCC;
          text-decoration: none;

          transition: $transition;
          // &:hover {
          //   box-shadow: 5px 5px 5px #CCC;
          //   transform: scale(1.1);
          // }

          // img {
          //   position:absolute;
          //   top:0;left:0;
          //   width: 100%;
          //   height: 100%;
          //   object-fit: cover;
          //   font-family: 'object-fit: contain;';
          // }
        }
      }

      ._email {
        word-break: break-all;
      }

      ._icon {

        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablette {
          display: block;
          text-decoration: underline;
        }

        &:hover {
          p {
            color: $colorPrimaryDark;
          }
          svg {
            background-color: $colorGrey;
          }
        }

        p {
          display: none;
          @include fs(14);
          color: $colorPrimary;
          transition: $transition;

          @include tablette {
            display: block;
          }
        }

        svg {

          width: 40px;
          height: 40px;
          padding: 13px;
          border-radius: 50%;

          transition: $transition;

          @include tablette {
            display: none;
          }
        }
      }

      ._mb_hidden {
        @include tablette {
          display: none;
        }
      }

      
      
    }
  }

}