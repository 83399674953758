.security-form-container {
  padding: 20px;
  .-qrcode-container {
    margin-top: 20px;
    display: flex;

    @media screen and (max-width:500px) {
      display: block;
    }

    .-infos {
      width: 100%;
      padding: 0 20px;

      @media screen and (max-width:500px) {
        padding: 20px 0;
        padding-bottom: 0;
      }
    }
  }
}
