.container-rstpwd {

  height: 100vh;
  margin: 0 auto;
  width: 100%;

  .container {
    width: 50%;
    margin: auto;

    .-logo {
      margin-bottom: 10px;
      text-align: center;

      p {
        @include fs(13);
        color: $colorPrimary;
      }
    }

    .btn {
      margin-top: 16px;
    }
  }
}