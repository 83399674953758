.dashboard-header {

  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  @include tablette {
    padding: 20px;
  }
  @include mobile {
    padding: 20px 20px 0;
  }


  .-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // @include tablette {
    //   width: 60%;
    // }

    h2 {
      @include fs(24);
      color: #171725;

      @include tablette {
        @include fs(18);
      }
      @include mobile {
        @include fs(18);
      }
    }
  }

  .-right {
    display: flex;
    justify-content: flex-end;
    
    // @include tablette {
    //   width: 40%;
    // }

    .-search {
      display: flex;
      align-items: center;
      padding: 0 20px;

      .-inner {
        height: 35px;
        border-radius: 35px;
        display: flex;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 1px 1px 5px #ccc;

        .-icon {
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        

        .-input {
          background-color: none;
          border: none;
          min-width: 250px;
          outline: none;
          padding-left: 5px;
        }
      }
    }

    .-profile {
      position: relative;
      display: flex;
      justify-content: space-between;

      .-link {
        text-decoration: none;
        display: flex;
        align-items: center;

        .-name {
          flex: 1;
          // padding: 0 0 0 10px;
          // display: flex;
          // align-items: center;
          @include fs(14);
          color: $colorPrimary;
          font-weight: $semibold;

          @include mobile {
            display: none;
          }
        }
  
        .-img {
          width: 42px;
          height: 42px;
          margin-left: 20px;
          aspect-ratio: 1/1;
          border-radius: 50%;
          overflow: hidden;
          background-color: $colorPrimary;
          display: flex;
          justify-content: center;
          align-items: center;
  
          img {
            display: none;
            width: 100%;
          }
  
          p {
            // display: none;
            color: #fff;
            @include fs(16);
            font-weight: $bold;
          }
        }
      }

      .-hamburger {
        cursor: pointer;
        border: none;
        width: 40px;
        margin-left: 10px;
        display: none;
        
        @include mobile {
          display: block;
        }
      }
    }
  }

  // display: flex;
  // justify-content: space-between;
  // width:100%;
  // margin: 10px;
  // // padding: 20px 0 0 4.6%;
  // ._curUser{
  //   color: $colorPrimary;
  //   font-size: $bold;
  //   line-height: 24px;
  //   padding-right: 30px;
    

  //   a{
  //     text-decoration: none;
  //     font-size:23px;
  //   }

  //  .avatar{
  //     min-width:30px;
  //     margin-left: 10px;
  //     border-radius :50px;
  //     background-color:$colorPrimary;
  //     color:white
    
  //  }
  // }
  // h1{
  //   font-size: 24px;
  // }
  
}