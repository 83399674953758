
.input {
  display: inline-block;
  width: 100%;
  
  input::-webkit-calendar-picker-indicator {
    opacity: 100;
  }
  .-quizz_input{
    display: inline-block;
    width: 100%;
  }
 &.-big{
  span {
    display: block;
    @include fs(13);
    margin-bottom: 5px;
    color: $colorText;

    &.-required {
      &::after {
        color: $colorError;
        margin-left: 2px;
      }
    }
  }
  .-textarea{
    border:1px solid #c3d1df;
    width: 100%;
    height: 100px;
    max-width: 1090px;
    resize: none;
    padding: 10px;
    font-family: inherit;
    border-radius: $borderRadiusInput;
    }
 
  }
  span {
    display: block;
    @include fs(13);
    margin-bottom: 5px;
    color: $colorText;

    &.-required {
      &::after {
        content: "*";
        color: $colorError;
        margin-left: 2px;
      }
    }
  }

  input{
    height: 40px;
    width: 100%;
    padding-left: 10px;
    border-radius: $borderRadiusInput;
    outline: transparent;
    @include fs(13);
    border: 1px solid #c3d1df;
    border: none;
    background: $colorInputBackground;
    color: $colorText;
    transition: .3s ease-in-out;
    &:hover, &:focus{
      background: #fff;
    }
  }
  input[type="date"]{
    padding-right: 15px;
    font-family: inherit;
    letter-spacing: 1px;
    cursor: pointer;
  }
  &.-readonly{
    input, input:read-only, textarea:read-only, select:disabled{
      background: #efefef;
      cursor:not-allowed;
    }
  }

  input:read-only, textarea:read-only, select:disabled{
    background: #efefef;
    cursor:not-allowed;
  }
  
  .-select-wrapper {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: $colorInputBackground;
    border-radius: $borderRadiusInput;
    border: 1px solid #c3d1df;
    overflow: hidden;
    
    select {
      position: relative;
      width: 100%; height: 100%;
      @include fs(13);
      color: $colorText;
      border: none;
      padding: 10px;
      cursor: pointer;
      outline: none;
      background: transparent;
      padding-right: 50px;
      appearance: none;
    }

    .-icon {
      position: absolute;
      right: 0;top: 0;
      width: 50px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #c3d1df;
    }

  }

  &.select-style-2 .-select-wrapper {
    background-color: #FFF;
    select {
      color: $colorPrimary;
    }
    .-icon {
      border-left-color: #F1F1F5;
    }
  }

  p {
    font-size: 12px;
    margin-top: 5px;
    padding-left: 5px;
    @include fs(10);

    &.-error {
      color: $colorError;
    }

    &.-indication {
      color: $colorTextBlue;
    }
  }

  &.-error {
    input {
      border: 1px solid $colorError;
    }
  }

  &.-transparent {

    
    span {
      color: $colorTextWhite;
    }

    input {
      border: 1px solid #ccc;
      background-color: rgba(#fff, .5);
      color: $colorTextWhite;
    }

  }

  &.-red {
    span {
      color: $colorText;
    }
    
    input {
      background-color: #fff;
      color: $colorSecondary;
      font-weight: $semibold;
      border: none;
    }
  }

  //Fix zooming issue on mobile devices
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
      @include fs(16);
    }
  }
      
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    select:focus,
    textarea:focus,
    input:focus {
      @include fs(16);
    }
  }

}

.code {

  input {
    padding: 0;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }

}

.password {

  .-container {
    position: relative;
    display: flex;

    input {
      padding-right: 45px;
    }

    i {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      
      svg {
        height: 15px;
      }
    }
  }

  &.-blue {
    svg {
      fill: #B5BED0;
    }
  }

  &.-red {
    svg {
      fill: $colorSecondary;
    }
  }
}