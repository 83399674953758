.information-template {
  text-emphasis: none;
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .-inner {
    max-width: 500px;
    text-align: center;
    padding: 20px;

    .-icon {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      background-color: rgba($colorSuccess, .1);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin: 0 auto;
      margin-bottom: 30px;

      &.-error {
        background-color: rgba($colorError, .1);
      }

      &.-infos {
        background-color: rgba($colorInfo, .1);
      }

      svg {
        width: 60%;
      }
    }

    h3 {
      @include fs(20);
      font-weight: $semibold;
      color: $colorText;
      margin-bottom: 20px;
    }

    p {
      @include fs(16);
      color: $colorText;
    }
  }


}