.faq-container{
max-width: 1050px;

.-object{
  background-color: #fff;
  padding: 20px;
   border: 1px solid #E2E2EA ;
  border-radius: 20px;
  margin-bottom: 20px;

  .-pointer-div{
    cursor: pointer;

    .-icon{
      float: right;
      @include fs(30);
      margin: 0;
      
     //  position: absolute;
     //  top: 50%;
     //  -ms-transform: translateY(-50%);
      transform: translateY(-30%);
      color: $colorPrimary
     }
     .-title{
       @include fs(16);
       font-weight: $semibold;
      
     }
  }

 
  .-content{
    display: none;
    margin-top: 20px;
    img{
      display: block;
      margin: 0 auto;
    }
    @include fs(13)
  }
}
}