.loading-template {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0; top: 0;
  left: 0; right: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f5;
  transition: .3s ease-in-out;

  .dual-spinner {
    display: flex;
    justify-content: center;
    flex-direction: column;
  
    &:after {
      content: " ";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 5px solid;
      border-color: $colorSecondary transparent $colorSecondary transparent;
      animation: lds-dual-ring 1.2s linear infinite;
      margin: 0 auto;
    }
  
  }
}



@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}