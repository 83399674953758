
.container-detail{
  max-width: 1050px;

  .fond-presentation{
    display: grid;
    grid-template-columns:repeat(2,1fr);
    padding:20px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #E2E2EA ;
    border-radius: 20px;

    
    .-text-btn{
      padding:10px;

      p{
        @include fs(35);
        margin-bottom:10px;
      }
    }
    @include laptop {
      grid-template-columns: repeat(1, 1fr);
      text-align: center;
      .-img{
        width: 100%;
        // padding: 20px;
        img{
          width: 50%;
        }
      }
    
      @include tablette {
        display: block;
        .-img{
          width: 100%;
          // padding: 20px;
          img{
            width: 100%;
          }
        }
      }
    }
  }
  .fond-infos{
    .information-container{
      .-information{
        .subtitle{
          font-weight: $semibold;
        }
        p{
          @include fs(14);
          margin-bottom: 10px;
          display: block;

          a,span{
            display: block;
          }
        }
      }
    }
  }
  .btn{
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    }
  .links{
   text-align: center;
   ul{


    li{
      position: relative;
      display: inline-block;
      padding:0 10px;
      @include fs(13);

      a{
        color:$colorPrimary
      }
    }
   }
  }
 
  
}
