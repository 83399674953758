.popup-deconnexion {
  background-color: rgba(#000, .5);
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;
  z-index: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .-inner {
    width: 100%;
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    max-width: 480px;
    box-shadow: 1px 1px 10px 1px #5A5A5A;

    .-content {
      display: flex;

      @include mobile() {
        display: block;
      }

      .-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80px;

        @include mobile() {
          margin: 0 auto;
        }

        svg {
          width: 100%;
        }
      }

      .-texts {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mobile() {
          text-align: center;
        }

        p {
          @include fs(16);
          color: $colorText;
        }
      }
    }
  }

}