.notif-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1090px;
  margin: 10px 0;
  padding: 15px;
  text-decoration: none;
  border: 2px solid $colorSuccess;
  border-radius: $borderRadiusBar;
  background: #fff;

  @include mobile {
    display: flex;
  }

  &:after{
    content:"";
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    padding: 3px;
    border: solid currentcolor;
    border-width: 0 3px 3px 0;
    transform: rotate(-45deg) translateY(-50%);
    transition: transform .2s ease-in-out;
  }
  
  &:hover{
    &::after{
      transform: translate3d(3px, 0, 0) rotate(-45deg) translateY(-50%);
    }
    .-content i{
      text-decoration: none;
    }
  }

  &.-warning {
    border-color: $colorInfo;
  }

  &.-error {
    border-color: $colorError ;
  }

  .-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include mobile {
      display: block;
      margin-bottom: 5px;
    }

    i {
      background-color: #FFF;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      svg {
        width: 70%;
      }
    }

  }

  .-text {
    color: $colorText;
    @include fs(14);
    line-height: 24px;
    margin-left: 15px;
    font-weight: $regular;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @include mobile {
      margin-left: 0px;
    }
  }

  .-content{
    // display: grid;
    // grid-template-columns: 25fr 1fr;
    position: relative;
    padding-right: 30px;
    margin-left: 5px;
    text-decoration : none;
    color:$colorText;
    i{
      text-decoration:underline;
      font-style: normal;
      font-weight: 600; 
    }
    @include tablette() {
      @include fs(12);
      align-items: center;
    }
    @include mobile() {
      @include fs(10);
      align-items: center;
    }
  }
}