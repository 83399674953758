.dashboard-container {
  width: 100%;
  max-width: $dashboardContainerMaxWidth;
  margin-top: 40px;

  &.--padding {
    padding: 0 40px;
  }

  @include mobile {
    margin-top: 20px;

    &.--padding {
      padding: 0 20px;
    }
  }

  .dashboard-scontainer {
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    &.-no-padding {
      padding: 0;
    }
    &.-information{
      width:100%
    }

    .input{
      input{
        border: 1px solid #c3d1df ;
      }
    }
    

  }
 

}
.-card-container{
  row-gap: 20px;
}