.stps {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #0062FF;

  .container-stp {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    max-width: 768px;
    height: 100%;
    padding: 40px;

    @include mobile {
      padding: 20px;
    }

    .header-Stp {
      padding: 2em;
      color: #FFFFFF;
      font-weight: bold;
      font-style: italic;
      text-align: center;
      
    }

    .body-Stp {
      padding: 0em;
      margin: 0 auto;
      color: #FFFFFF;
      text-align: center;
      text-justify: auto;

      span {
        font-size: 14px;
      }
    }

    .footer-Stp {
      padding: 40px 0;
    }
  }

  .container-end {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    max-width: 768px;
    height: 100%;
    padding: 40px;

    @include mobile {
      padding: 20px;
    }

    .body-end {
      padding-top: 15em;
      margin: 0 auto;
      color: #FFFFFF;
      text-align: center;
      text-justify: auto;

      span {
        font-size: 13px;
      }
    }

    .footer-end {
      padding: 40px 0;




    }
  }
}

.quizz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  position: relative;
  background: lighten($colorSecondary, 30%);

  .container-quizz {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-width: 768px;
    padding: 40px;

    @include mobile {
      padding: 20px;
    }

    .header {
      margin-bottom: 40px;
      color: $colorSecondary;
      font-weight: $bold;
      font-style: italic;
      text-align: center;
    }

    .body {
      height: 100%;
      max-height: 850px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .back{
        color: $colorSecondary;
        font-weight:bold;
        cursor: pointer;
        img{
          margin-right:5px
        }
      }

      .question {
       
        p {
          color: $colorSecondary;
          display: flex;
          align-items: center;

          i {
            display: inline-block;
            font-style: normal;
          }

          img {
            display: inline-block;
            position: relative;
            margin-right: 10px;
          }
        }

        p {
          margin-top: 10px;
          font-weight: bold;
        }
      }

      .reponse {

        .ulQuizz{
          @include fs(18);
          padding-bottom: 40px;
          font-weight: $semibold;

        }
        .liQuizz {
          @include fs(14);
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 20px;
          font-weight: $semibold;

        }
        .separator {
          padding-bottom: 20px;
        }
        label {
          @include fs(18);
        }

        input {
          
          border: none;
          color: $colorSecondary;
          font-weight: bold;
          border-radius: 10px;
          height: 50px;
          padding-right: 10px;
          font-size: 16px
        }
      }

      .-reponse {
        padding: 25px 0px 5px 0px;
        left: 0 auto;
        right: 0 auto;
        

        .choice {
          margin: 0 0 1em;
          padding: 1em;
          border: solid 1px white;
          border-radius: 7px;
          background-color: white;

          .form-row-checkbox {
            label {
              i {
                border-color: $colorSecondary;
                background-color: white;
              }

              ::after {
                background-color: $colorSecondary;
              }

              span {
                color: $colorSecondary;
                font-weight: bold;
              }
            }
          }

          label {
            input {
              margin-right: 10px
            }
          }
        }
      }

      .indication {
        @include fs(13);
        margin-bottom: 20px;
        position: relative;
      }
    }

    .footer {
      padding: 5px;

      button {
        margin: 0 auto;
      }
    }
  }
}