.layout-profile-container {
  padding: 40px;
  @include tablette {
    padding: 20px;
  }
  @include mobile {
    padding: 20px;
  }

  .-subtitle{
    display: flex;
    flex-direction:row;
    padding: 10px;
    @include fs(13);
    color: $colorTextGrey;
    text-transform: uppercase;
    
    h3{
      color: $colorTextGrey;
      text-transform: uppercase;
      @include fs(13);
    }
  }
  .-medium{
    max-width:990px ;
  }
}