.-big-card-container{
 margin-bottom:40px;

 .-notifContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  .-hide-notif{
   height: 20px;
    width: 20px;
    margin-left:10px;
    color: $colorText;
    font-weight: $bold;
    text-align: center;
    line-height: 1;
    outline: none;
    border: 1px solid $colorText;
    cursor: pointer;
    transition: .3s ease-in-out;
    &:hover{
      color: #fff;
      background: $colorText;
    }
  }
 }
}
.title{
  color:#92929D;
  text-transform: uppercase;
  @include fs(13);
  display:flex;
  flex-direction:row;
  
  h3{
    color:#92929D;
    text-transform: uppercase;
    @include fs(13);
    }
    svg{
      margin-right: 10px;
    }
 
}
.-card-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
 
  @include fs(13);

 
  @media ( max-width: $widthLaptop ) { 
    grid-template-columns:repeat(3,minmax(0,300px)) ;
    
    gap:20px;
    @include tablette {
      grid-template-columns:  1fr ;
      }
  }

 

  .card{
    background-color: #ffffff;
    width:354px;
    height:auto;
    border-radius: 20px ;
    border: solid 1px #E2E2EA ;
    @include laptop{
      width: 100%;
      height: auto;

    }
    @include tablette {
      width:auto;
      height:auto;
      margin-bottom: 10px ;
    }
  
    
    .card-header{
      border-bottom: solid 0.5px #E2E2EA;
      padding: 20px  ;
      label{
      font-weight: 600;
       color:$colorText
      }
    }
    .card-body{
      padding:20px;

      .-texts{
        margin-bottom: 20px;
      }
      .-amount{
            @include fs(35);
            color:$colorPrimary;
            font-weight: $semibold;
          }
      .-amountGrey{
            @include fs(35);
            color:$colorwithdrawal;
            font-weight: $semibold;
          }
          .-card-body-text{
            color:#92929D;
            span{
              color:$colorText;
              font-weight: $semibold;
            }
          }
          .-card-body-btn{
            margin-top: 30px;
              button{
                width:60%;
                height: 38px;
                border-radius: 19px;
                @include laptop{
                  width:70%;
                  height: 38px;

                }
                @include tablette{
                  width: 70%;
                  height: 38px;

                }
                @include mobile{
                  width: 70%;
                  height: 38px;
                }
              }
          }
          .huge{
            width: 300px;
          }
          .-link{
            display:flex;
            align-items: center;
            margin:21px 5px 0 0;
            // padding-left:15px;
            height:50px;
            border-radius:8px;
            border: 1px solid #c3d1df;
            background-color: $colorInputBackground;
            
            span{
              border:none;
              background-color: $colorInputBackground;
              @include fs(13);
              text-decoration:none;
              color:#7385A0;
            }
            input{
              display: block;
              width:100%;
              height: 100%;
              padding: 0 10px;
              color:#7385A0;
              border:none;
              border-radius:8px;
              background-color: $colorInputBackground;
              cursor: pointer;
            }
            ::after{
              border:none;
            }
          }
    }
    &.-apport{
      .card-body{
       .-amount{
        @include fs(35)
       }
      }
    }
   
  }
  .first-elmt{
    @media ( max-width: $widthLaptop ) { 
      width:100%;
      @include tablette {
        height:250px;
        width:100%
        }
    }

    
    }
   
  
  .-btn{
    cursor:pointer;
    background-color: $colorPrimary;
    height:244px;
    &:hover {
      background-color: $colorPrimaryDark;
    }
    @media ( max-width: $widthLaptop ) { 
      grid-template-columns:repeat(2,1fr) ;
      width:100%;
      height:264px;
      gap:20px;
      @include tablette {
        height:250px;
        width:100%
        }
    }
  
   
    .btn-text{
      font-weight:700;
      color:#fff;
      margin-top: 10px;
    }
    .btn-text2{
      font-weight:700;
      color:#fff;
      margin-top: 10px;
      width: 70%;
      margin-left: 50px;
      margin-right: 50px;
    }
  }
  .-disable{
      
    cursor: default;
    // background-color: #e5e5e5;
    color: #ababab;
    svg{
      display:none;
    }
    &:hover{
      // background-color: #e5e5e5;
    }
}
.-inactive{
      
  cursor: default;
  background-color: #e5e5e5;
  color: #ababab;
  svg{
    display:none;
  }
  &:hover{
    background-color: #e5e5e5;
  }
}

  }
 