
// markup style
.markup {
	// @include fs(16);
	// line-height: 1.4;
	
	>* {
		margin-bottom: 40px;
	
		&:last-child {
			margin-bottom: 0;
		}
	}
	p {
	
	}
	.custom-format{
		opacity: 0;
		visibility: hidden;
	}
	a:not(.custom-format) {
		// @extend .link-fx--underlined;
		text-decoration: none;
		font-weight: $semibold;
		color: $colorPrimary;
		// padding-bottom: 6px;
		//	white-space: nowrap;
	}
	b,strong {
		font-weight: $semibold;
	}
	ul,ol{
		margin: 20px 0 0 10px;
    list-style: none;
		ul, ol{
			padding-left: 30px;
      list-style: none;
		}
		li{
			margin-bottom: 20px;
		}
	}
	ul {
		>li {
			position: relative;
			padding-left: 15px;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 6px;
				height: 0;
				height: 6px;
				width: 6px;
				border-radius: 5px;
				background-color: $colorPrimary;
				// transform: translateY(-50%);
			}
		}
	}
	ol {
		counter-reset: list-ol;
		>li {
			position: relative;
			&:before {
				counter-increment: list-ol;
				content: counters(list-ol, '.') ".";
				margin-right: 5px;
				font-weight: $semibold;
				text-align: right;
				color: $colorPrimary;
			}
		}
	}
	h1, h2{
		// @extend .section-title-h2;
		padding-bottom: 10px;
    margin-bottom: 10px;
	}
	h3{
		// @extend .section-title-h3;
    // padding-bottom: 10px;
    // margin-bottom: 10px;
	}
	h4, h5, h6 {
		// @extend .section-title-h4;
    padding-bottom: 10px;
    margin-bottom: 10px;
	}
 iframe, .wp-video{
    display: block;
    margin: 0 auto;
  }
	img {
		max-width: 100%;
		height: auto;
		&.alignright{
			float: right;
			margin: 0 0 5px 40px;
		}
		&.alignleft{
			float: left;
			margin: 0 40px 5px 0;
		}
		&.alignnone{
			display: block;
		}
		&.aligncenter{
			margin: auto;
			display: block;
			margin: 10px auto;
		}
	}

	// table{
	// 	margin: auto;
	// 	margin-bottom: 20px;
	// 	text-align: center;
	// 	border-spacing: 10px 6px;
	// 	font-weight: $normal;

	// 	tr {
	// 		color:$colorPrimary;

	// 		&:first-child{
	// 			color:$colorSecondary;
	// 		}
	// 	}

	// 	td {
	// 		padding : 6px 20px;
	// 		background-color: $lightblue;
	// 		width: 50%;
	// 	}
	// }

	table {
		width: 100% !important;
		height: auto;
		margin-top: 30px;
		border-collapse: collapse;
		border: 0;
		color: $colorText;
		line-height: 1.4;
		tr:first-child th{
			&:first-child {
				border-radius: 4px 0 0 0;
			}
			&:last-child {
				border-radius: 0 4px 0 0;
			}
		}
		th{
			padding: 15px 20px;
			@include fs(18);
			font-weight: $semibold;
			color: $colorTextWhite;
			letter-spacing: .2px;
			vertical-align: middle;
			// background: $blue;
			// line-height: 1;
			// border: 1px solid rgba($lightblue,.1);

		
		}
		td{
			vertical-align: middle;
			padding: 15px 20px;
			border: 1px solid rgba($colorText,.15);
			color: $colorText;
			// @include fs(16);
			// font-weight: $normal;

		
		}
		th, tr, td {
			height: auto !important;
		}
		tr:nth-child(even){
			// background: $lightblue;
		}
		// JS calculate nbr of cols => markupBtn.markupTable();
		// &.-nbr-col-1 th{ width: 100% !important; }
		// &.-nbr-col-2 th{ width: 50% !important; }
		// &.-nbr-col-3 th{ width: 33.3333333% !important; }
		// &.-nbr-col-4 th{ width: 25% !important; }
		// &.-nbr-col-5 th{ width: 20% !important; }
	
	}

	blockquote {
		width: 100%;
		max-width: 1080px;
		display: block;
		position: relative;
		margin: 30px auto;
		padding: 20px 30px;
		@include fs(18);
		// font-weight: $normal;
		color:$colorPrimary;
		line-height: 1.4;
		text-align: left;
		// background: $lightblue;
		// border: 2px solid $blue;
		border-radius: 10px;
		&:before, &:after{
			@include fs(40);
			position: absolute;
			line-height: 0;
		}
		// &:before{
		// 	content:"«";
		// 	top: 10px;
		// 	left: -25px;
		// }
		// &:after{
		// 	content:"»";
		// 	bottom: 20px;
		// 	right: -30px;
		// }
	
	}
}

// Markup cols
.markup__2cols {

}




/* MARKUP MODIFIERS
--------------------- */

.markup__space-md {
	>*,
	>p {
		&:first-child {
			margin-top: 40px;
		}
	}
}

.descriptions {
	@include fs(18)
}
