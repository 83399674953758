.double-auth-form {

  .-title {
    color: $colorTextWhite;
    font-weight: $regular;
    @include fs(16);
    margin-bottom: 30px;
  }

  .-forget {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 20px;

    button {
      cursor: pointer;
      color: $colorTextWhite;
      background-color: transparent;
      border: none;
      @include fs(13);
    }

    a {
      color: $colorTextWhite;
      @include fs(13);
    }
  }
}