$colorPrimary: #0062ff;
$colorPrimaryDark: darken($colorPrimary, 10%);
$colorPrimaryLight: lighten($colorPrimary, 10%);

$colorSecondary: #fc5a5a;
$colorSecondaryDark: darken($colorSecondary, 10%);
$colorSecondaryLight: lighten($colorSecondary, 10%);

$colorGrey: #f0f0f0;
$colorGreyDark: darken($colorGrey, 10%);
$colorGreyLight: lighten($colorGrey, 10%);

$colorText: #222;
$colorTextGrey: #696974;
$colorTextWhite: #ffffff;
$colorTextBlue: #7385a0;

$colorInfo: #ffc542;
$colorInfoLight: lighten($colorInfo, 25%);
$colorInfoDark: darken($colorInfo, 14%);

$colorError: #f44336;
$colorErrorLight: lighten($colorError, 25%);
$colorErrorDark: darken($colorError, 14%);

$colorSuccess: #3dd598;
$colorSuccessLight: lighten($colorSuccess, 25%);
$colorSuccessDark: darken($colorSuccess, 14%);

$colorWaiting: #ff974a;
$colorWaitingLight: lighten($colorWaiting, 25%);
$colorWaitingDark: darken($colorWaiting, 14%);

$colorwithdrawal: #92929d;
$colorwithdrawalLight: lighten($colorwithdrawal, 25%);
$colorwithdrawalDark: darken($colorwithdrawal, 14%);

$colorInputBackground: #f4f9fe;
$colorDashboardBackground: #f1f1f5;

$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

$borderRadiusButton: 25px;
$borderRadiusInput: 5px;
$borderInfoBox: 10px;
$borderRadiusBar: 20px;

$dashboardContainerMaxWidth: 1100px;

$transition: all linear 0.28s;

// dependencies
@import "dependencies/reset";
@import "dependencies/mixin";

// Global
@import "part/global";

//markup
@import "part/markup";

// Compenents
@import "part/button";
@import "part/input";
@import "part/infos";
@import "part/infos-bar";
@import "part/stepper-head";
@import "part/form-row";
@import "part/form-header";
@import "part/form-footer";
@import "part/form-section-title";
@import "part/form-user-contact";
@import "part/information-template";
@import "part/loading-template";
@import "part/navbar";
@import "part/subnav";
@import "part/popup-deconnexion.scss";
@import "part/notification-bar";

@import "part/dashboard-header";
@import "part/dashboard-container";

// Forms
@import "part/form-auth";
@import "part/form-double-auth";
@import "part/form-register-connexion-infos";

// Views
@import "part/view-authentification";
@import "part/view-registration";
@import "part/view-quizz";
@import "part/view_security";
@import "part/view_reset-pwd";
@import "part/view-first-investement";
@import "part/view-notifications";
@import "part/view-fiscality";
@import "part/view-investment";
@import "part/view-dashboard";
@import "part/view-transaction";
@import "part/view-document-commission";
@import "part/view-document-contract";
@import "part/view-document-payment";
@import "part/view-apport-affaire";
@import "part/view-details-apport-affaire";
@import "part/view-mon-reseau";
@import "part/view-withdraw";
@import "part/RIB-form";
@import "part/real-detail";
@import "part/viewConractList";
@import "part/viewAddContact";
@import "part/view-projet-immo";
@import "part/view-partenaires";

//Layouts
@import "part/layout-protected-roots";
@import "part/layout-dashboard";
@import "part/layout-profile";
@import "part/view_faqs";

@import "part/feedback";

//Container
// @import "part/dashbord-container";

//Logout btn
@import "part/logoutBtn";
