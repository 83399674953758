$widthDesktop: 1920px;
$widthLaptop: 1440px;
$widthTablette: 1024px;
$widthMobile: 767px;


@mixin desktop {
  @media ( max-width: $widthDesktop ) { @content }
};

@mixin laptop {
  @media ( max-width: $widthLaptop ) { @content }
};

@mixin tablette {
  @media ( max-width: $widthTablette ) { @content }
};

@mixin minTablet {
  @media only screen and (min-width: $widthTablette) { @content; }
};

@mixin mobile {
  @media ( max-width: $widthMobile ) { @content }
};

@mixin fs($sizeValue: 16) {
  font-size: $sizeValue + px;
  font-size: calc($sizeValue / 16) + rem;
}


/*
  cover image
  the aspect ratio of the images is preserved.
*/
@mixin img-cover {
  object-fit: cover;
  // polyfill with ofi.js for older browsers
  font-family: 'object-fit: cover;';
}

/*
  wrapper with set ratio + image cover
  the aspect ratio of the images is preserved.
  # apply it to the direct parent of image
*/
@mixin img-cover-ct {
  position: relative;
  height: 0;
  padding-bottom: 50%;
  > img {
    @include img-cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

/*
  POSITION CENTER
  div {
    @include centerer('vertical');
  }
*/
@mixin centerer($position: '') {
  position: absolute;
  top: 50%;
  @if $position=='vertical' {
    transform: translate(0, -50%);
  } @else if $position=='horizontal' {
    top: inherit;
    left: 50%;
    transform: translate(-50%, 0);
  } @else {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}