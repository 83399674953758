.form-header {
  margin-bottom: 60px;
  text-align: center;

  h3 {
    color: $colorTextBlue;
    @include fs(20);
    font-weight: $semibold;
  }
  p {
    color: $colorPrimary;
    @include fs(20);
    font-weight: $semibold;
  }

}