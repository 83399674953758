.add-RIB{

    margin: 0;
    padding:0;
    font-family: "Poppins",sans-serif;
    .error{
      font-size: 15px;
      padding-bottom: 10px;
      color: red;
    }
    .title{
      margin-left: 50px;
      text-transform: none;
      font-size: 24px;
      color: #171725;
      font-family: 'Poppins' , sans-serif;
     
         @include mobile{
          margin: 0;
          padding:0;
          display: flex;
          justify-content: center;         
  
        }
      
    }
    .spacing{
        padding-bottom: 20px;

        span{
          @include fs(11);
          font-style: italic;
        }
        
        
    }
    .spacing-rib{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;
      padding-top: 20px
    }
    .rib-dwl{
      @include fs(14);
    }
    .RIB-container {
      width: 30%;
      height: auto;

      @include desktop{
        width: 40%;
      }
          @include laptop {
            width: 60%;

          }
        @include tablette {
          width: 100%;
          display: flex;
          justify-content: center;
  
         @include mobile{
          width: 100%;
          display: flex;
          justify-content: center;         
  
        }
      }
    
    .RIB-dashboard-container {
      width: 100% ;
      max-width: $dashboardContainerMaxWidth;
      margin-top: 20px;
        
    
      
      .RIB-dashboard-scontainer {
        background-color: #FFF;
        padding: 20px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;
        
        
    
        @include mobile {
          margin-bottom: 20px;
        }
    
        &:last-child {
          margin-bottom: 0px;
        }
    
        &.-no-padding {
          padding: 0;
        }
        .RIB-section-title {
          margin-bottom: 30px;
          border-bottom: 2px solid #CCC;
          
          .RIB-texte {
            font-size: 15px;
            margin-bottom: 10px;
            color: #050506;
            font-weight: 600;
          }
        }
      }
    }
    
    .RIB-form{

      margin:25px 40px 25px 40px;
      .btn {
        display: flex;
        margin-top: 30px;
        justify-content: center;
      }
    }
        
      
  }
  }
  .nextFormTrigger{  
    margin-top: 20px;
    margin-left: 50px; }
  .file-upload{
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    border-bottom: 2px solid #CCC;
  }
  