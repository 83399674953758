.layout-AA-container {
    padding: 20px 40px;
    //  perspective: 800px; //! pour determiner l'element englobant du footer
  
    @include mobile {
      padding: 20px;
    }
    //* css commmision bloc
    .-contact-list-container{
        margin-bottom:40px;
            
    .-button-container{
        margin:0 0 10px 0;
        padding:0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1090px;
        .-add-contact{
            width: 20%;
            @include mobile{
                width: 100%;
            }
        }
        .-button-wrapper {
          width: 100%;
          height: 40px;
          background-color: $colorPrimary;
          border-radius: 40px;
          border: none;
          max-width: 250px;
          margin-left: 20px;
          position: relative;
          text-decoration: none;
    
          display: flex;
          overflow: hidden;
          transition: $transition;
    
          @include tablette {
            max-width: inherit;
            margin-left: 0px;
            margin-bottom: 20px;
          }
    
          &:hover {
            background-color: darken($colorPrimary, 5);
          }
    
          .-icon {
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
          }
    
          button {
            padding: 0 20px;
            border: none;
            background-color: transparent;
            width: 100%;
            @include fs(14);
            font-weight: $semibold;
            color: #FFF;
            cursor: pointer;
          }
    
        }
    }

    
       
    .contact-container {
      width: 100%;
      max-width: 1090px;
      margin-top: 40px;
  
      &.--padding {
        padding: 0 40px;
      }
  
      @include mobile {
        margin-top: 20px;
  
        &.--padding {
          padding: 0 20px;
        }
      }
     
      .contact-scontainer {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;
  
        @include mobile {
          margin-bottom: 20px;
        }
  
        &:last-child {
          margin-bottom: 0px;
        }
  
        &.-no-padding {
          padding: 0;
        }
        &.-information {
          width: 100%;
        }
      }
      .-big{
        width: 100%;
        height: 300px;
        max-width: 1090px;
        margin-top: 40px;
        @include mobile {
          margin-top: 20px;
        }
      }
     
      .-checkbox {
        padding-bottom: 20px;
        border-bottom: solid 1px #e2e2ea;
        margin-bottom: 20px;
        
        p{
          margin-bottom: 20px;
        }
        .-fileupload {
          display: flex;
          flex-direction: column;
          padding: 10px 0 0 30px;

          span{
            @include fs(11);
            font-style: italic;
          }
        }
      }
      
    }

      .contact-form-section-title{

        margin-bottom: 30px;
        border-bottom: 1px solid $colorPrimaryLight;
        display: flex;
        justify-content: space-between;
        .Search-bar{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
  
          .input{
          
          &.search{
            width: 100%;
            max-width: 220px;
            margin-right: 10px;
            border-radius: 2px;
            @include tablette {
              width: 100%;
              margin-right: 0px;
              margin-bottom: 20px;
            }
            @include mobile {
              margin-right: 0px;
              margin-bottom: 20px;
            }
            input{
              height:40px;
              border-radius: 25px;
              color:black;
              background: url('../../assets/svg/search-Icon.svg') no-repeat 10px 50%;
              background-color: $colorInputBackground;
              padding-left: 40px;
              background-size: 20px;
    
            }
          }}
          .btn-search{
            width: 100%;
            max-width: 70px;
            @include tablette {
              width: 50%;
              margin-right: 0px;
              margin-left: 5px;
              margin-bottom: 20px;
            }
            @include mobile {
              margin-right: 0px;
              margin-left: 5px;
              margin-bottom: 20px;
            }
          }
        }
        .-texte {
          text-transform: uppercase;
          @include fs(13);
          margin-bottom: 10px;
          width: 100%;
          color: $colorTextBlue;
          font-weight: $semibold;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      
        &.-simple {
          border-bottom: 1px solid $colorGrey;
        }
      
        &.-simple-2 {
          padding: 20px;
          padding-top: 25px;
          border-bottom-color: #E2E2EA;
          margin-bottom: 0;
          .-texte {
            @include fs(14);
            text-transform: inherit;
            margin-bottom: 0;
            color: #171725;
          }
      }
      
    }
    
  
    ._contact_container {
      padding: 20px;
      @include tablette {
        padding: 0px;
      }
  
      // ._contact_mb_title {
      //   display: none;
      //   @include fs(14);
      //   font-weight: $semibold;
      //   color: $colorTextBlue;
      //   // margin-bottom: 15px;
  
      //   @include tablette {
      //     display: block;
      //   }
      // }
  
      ._contact_header {
        display: grid;
        grid-template-columns: 50px 4fr 3fr 4fr 4fr 3fr 3fr 100px;
        background-color: #fafafb;
        border-radius: 10px;
        padding: 7px;
  
        @include tablette {
          display: none;
        }
  
        span {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          @include fs(11);
          font-weight: $semibold;
          color: $colorTextBlue;
        }
      }
  
      ._contact_list {
        padding: 20px 0;
  
        @include tablette {
          padding: 0px;
        }
        
        ._contact {
          display: grid;
          grid-template-columns:  60px 4fr 3fr 4fr 4fr 3fr 3fr 105px;
          border-radius: 5px;
          padding: 7px;
  
          transition: $transition;
  
          @include tablette {
            background-color: #fff;
            margin-top: 15px;
            border-radius: 10px;
            display: block;
            padding: 20px;
          }
          &:hover {
            box-shadow: 2px 2px 10px #ccc;
            transform: scale(1.01);
          }
  
          .-succes {
            color: $colorSuccess;
          }
          .-failed {
            color: $colorError;
          }
          .-waiting {
            color: $colorWaiting;
          }
  
          div {
            @include fs(12);
            padding: 10px;
            display: flex;
            align-items: center;
            color: #000;
  
            &::before {
              content: attr(data-label);
              margin-right: 5px;
              color: #a5a5a5;
              display: none;
  
              @include tablette {
                display: block;
              }
            }
  
            @include tablette {
              @include fs(14);
              padding: 0px;
              margin-bottom: 10px;
            }
          }
  
          ._picto {
            justify-content: center;
  
            @include tablette {
              display: none;
            }
  
            a {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              @include fs(14);
              font-weight: $semibold;
              color: #fff;
              position: relative;
              overflow: hidden;
              box-shadow: 1px 1px 5px #ccc;
              text-decoration: none;
  
              transition: $transition;
              // &:hover {
              //   box-shadow: 5px 5px 5px #CCC;
              //   transform: scale(1.1);
              // }
  
              // img {
              //   position:absolute;
              //   top:0;left:0;
              //   width: 100%;
              //   height: 100%;
              //   object-fit: cover;
              //   font-family: 'object-fit: contain;';
              // }
            }
          }
  
          ._email {
            word-break: break-all;
          }
  
         
          ._icon {

            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;

            @include tablette {
              display: block;
              text-decoration: underline;
            }

            &:hover {
              p {
                color: $colorPrimaryDark;
              }
              svg {
                background-color: $colorGrey;
              }
            }

            p {
              display: none;
              @include fs(14);
              color: $colorPrimary;
              transition: $transition;

              @include tablette {
                display: block;
              }
            }

            svg {

              width: 40px;
              height: 40px;
              padding: 13px;
              border-radius: 50%;

              transition: $transition;

              @include tablette {
                display: none;
              }
            }
          }
  
          ._mb_hidden {
            @include tablette {
              display: none;
            }
          }
        }
      }
    }
    //* css Prime Apoort contact bloc
    .contact-container_prime {
      width: 100%;
      max-width: 1090px;
      margin-top: 40px;
  
      &.--padding {
        padding: 0 40px;
      }
  
      @include mobile {
        margin-top: 20px;
  
        &.--padding {
          padding: 0 20px;
        }
      }
     
      .contact-scontainer_prime {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;
  
        @include mobile {
          margin-bottom: 20px;
        }
  
        &:last-child {
          margin-bottom: 0px;
        }
  
        &.-no-padding {
          padding: 0;
        }
        &.-information {
          width: 100%;
        }
      }
    }
  
    ._contact_container_prime {
      padding: 20px;
      @include tablette {
        padding: 0px;
      }
  
      // ._contact_mb_title {
      //   display: none;
      //   @include fs(14);
      //   font-weight: $semibold;
      //   color: $colorTextBlue;
      //   // margin-bottom: 15px;
  
      //   @include tablette {
      //     display: block;
      //   }
      // }
  
    }
}
  }
  