.sub-navbar-container {

  .sub-navbar {

    display: flex;
    margin-bottom: 20px;

    @include mobile {
      display: none;
    }

    li {
      margin-right: 20px;

      a {
        position: relative;
        @include fs(14);
        font-weight: $semibold;
        color: $colorText;
        text-decoration: none;
        transition: $transition;

        &::after {
          content: "";
          position: absolute;
          left: 0; bottom: -2px;
          width: 0%; height: 1px;
          background-color: $colorPrimary;
          transition: $transition;
        }

        &:hover,
        &.-active {
          color: $colorPrimary;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  .input {
    display: none;
    @include mobile {
      display: block;
    }
  }

}