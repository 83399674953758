.inv-container {
  // padding: 20px 40px;
  max-width:990px;
  @include fs(13);
  h3{
    padding:10px
  }
  .step-label{
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size:medium;
    font-weight: bold
    };
  .input{
    margin-top:20px;
    margin-bottom: 20px;
  }
  .inv-title{
    display:block;
    margin-top:20px;
    
    
  }
  .inv-radio{
    margin : 0 5px 0px 5px;
  }
  // .label-checkbox{
  //   // margin-top:15px
  // }
  // .inv-checkbox{
    
  //   // padding:9px 0 15px 0
  // }
  .parag{
    margin-bottom: 16px;
  }

  p.-error{
    
    font-size:10px;
    color:$colorSecondary
  }
  .-profil{
    color:$colorPrimary
  }
}