.form-footer {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include tablette {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-auto-flow: dense
  }

  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  .btn {
    width: 35%;
    transition: $transition;
    margin: 0 10px;

    @include tablette {
      width: 100%;
      margin: 0;
    }


    &:first-child {
      opacity: 1;
      transform: scale(1);
    }

    &:last-child {
      transform: translateX(0%);

      @include mobile {
        margin-bottom: 10px;
        transform: translateX(0%);
      }
    }

  }

  &.-first-step {

    div {
  
      &:first-child {
        opacity: 0;
        transform: scale(0);
      }

      &:last-child {
        transform: translateX(-50%);
        @include mobile {
          transform: translateX(0%);
        }
      }
  
    }

  }

}