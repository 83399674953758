.layout-AA-container {
    padding: 20px 0px;

    //  perspective: 800px; //! pour determiner l'element englobant du footer
  
    @include mobile {
      padding: 20px 0px;
    }
    
    
    //* css commmision bloc
    .-add-contact-container{
        margin-bottom:40px;
    .-radio-container{

     

      .-inputs-container{
        display: grid;
        grid-template-columns: 1fr 12fr;
        grid-gap: 20px;
        margin-bottom: 20px;
        @include mobile {
          // grid-template-columns: 1fr;
        }
        label{
          display: flex;
          align-items: center;
          height: 30px;
          span{
            margin-left: 5px;
          }
    
        }
       
      }
    }
    .-radio-container-form{
      .-inputs-container{
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 3fr 500px ;
        grid-gap: 20px;
        margin-bottom: 20px;
        @include mobile {
          // grid-template-columns: 1fr;
        }
        label{
          display: flex;
          align-items: center;
          height: 30px;
          span{
            margin-left: 5px;
          }
    
        }
       
      }
    }
    .-add-status{
      margin-bottom: 20px;
    }
    .contact-info-container{
    background-color: #fff;
    max-width:1090px;
    border-radius:$borderRadiusBar;
    border: solid 1px #E2E2EA ;
    margin-bottom: 20px;
    @include fs(13);
    @include mobile {
     width: 100%;

    }
    .-infp-checkbox {
      .input{
        margin-bottom: 15px;
      };
      
      p{
        margin-bottom: 20px;
      }
      .-fileupload {
        display: flex;
        flex-direction: column;
        padding: 10px 0 0 30px;

        span{
          @include fs(11);
          font-style: italic;
        }
      }
    }
    input{
      border :1px solid #c3d1df ;
    }
   

    &.-info-invest{
    margin-bottom: 20px;
    }
 
    .-title{
    border-bottom: 1px solid #E2E2EA;
    padding:20px;
  }
  .-small-title{
    border-bottom: 1px solid #E2E2EA;
    padding:20px 20px;
  }
  .-information{
    padding:20px;
    h1{
      font-weight: 500;
      font-size: 36px ;
    
    }
    .btn{
      margin-top:20px;
      button{
       border-radius:$borderRadiusButton ;
      }
    }
    
  }
  ._notes_list {
    padding: 20px 20px;
    max-width: 1090px;
    @include tablette {
      padding: 0px;
    }

    ._notes {
      display: flex;
      max-width: 1090px;
      flex-direction: column;     
      border-radius: 5px;
      padding: 7px;
      .-not-overflow-date{
        padding:0 0 0 10px;
        label{
          font-weight: bold;  
          @include fs(14);
          transition: $transition;
          @include tablette {
            display: block;
          }
          }
        }
        .-not-overflow-text{
        p{
          width: 100%;
          word-wrap: break-word;
          @include fs(14);
          transition: $transition;
          @include tablette {
            display: block;
          }
        }}
      transition: $transition;
      
      @include tablette {
        background-color: #FFF;
        margin-top: 15px;
        border-radius: 10px;
        display: block;
        padding: 20px;
      }
      // &:hover {
      //   box-shadow: 2px 2px 10px #CCC;
      //   transform: scale(1.01);
      // }
      
      .-succes{
        color:$colorSuccess
      }
      .-failed{
        color:$colorError
      }
      .-waiting{
        color: $colorWaiting;
      }

      div {
        @include fs(12);
        padding: 10px;
        display: flex;
        align-items: center;
        color:  #7385A0;

       

        &::before {
          content: attr(data-label);
          margin-right: 5px;
          color: #7385A0;
          display: none;

          @include tablette {
            display: block;
          }
        }

        @include tablette {
          @include fs(14);
          padding: 0px;
          margin-bottom: 10px;
        }
      }

      ._picto {
        justify-content: center;

        @include tablette {
          display: none;
        }

        a {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include fs(14);
          font-weight: $semibold;
          color: #FFF;
          position: relative;
          overflow: hidden;
          box-shadow: 1px 1px 5px #CCC;
          text-decoration: none;

          transition: $transition;
          // &:hover {
          //   box-shadow: 5px 5px 5px #CCC;
          //   transform: scale(1.1);
          // }

          // img {
          //   position:absolute;
          //   top:0;left:0;
          //   width: 100%;
          //   height: 100%;
          //   object-fit: cover;
          //   font-family: 'object-fit: contain;';
          // }
        }
      }

      ._email {
        word-break: break-all;
      }

      ._icon {

        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablette {
          display: block;
          text-decoration: underline;
        }

        &:hover {
          p {
            color: $colorPrimaryDark;
          }
          svg {
            background-color: $colorGrey;
          }
        }

        p {
          display: none;
          @include fs(14);
          color: $colorPrimary;
          transition: $transition;
          overflow: hidden;
          @include tablette {
            display: block;
          }
        }

        svg {

          width: 40px;
          height: 40px;
          padding: 13px;
          border-radius: 50%;

          transition: $transition;

          @include tablette {
            display: none;
          }
        }
      }

      ._mb_hidden {
        @include tablette {
          display: none;
        }
      }

      
      
    }
  }
      }
      .contact-scontainer {
        background-color: #FFF;
        padding: 20px;
        border-radius: 10px;
        overflow: hidden;
        .-separator{
          margin-bottom: 20px;
        }
        .separator{
            width: 100%;
            height: 1px;
            margin: 20px 0;
        }
    
        @include mobile {
          margin-bottom: 20px;
        }
       
        &:last-child {
          margin-bottom: 0px;
        }
    
        &.-no-padding {
          padding: 0;
        }
        .invest-section-title {
          margin-bottom: 30px;
          border-bottom: 2px solid #CCC;
        
          .invest-texte {
            font-size: 15px;
            margin-bottom: 10px;
            color: #050506;
            font-weight: 600;
          }
        }
      }
       
    .contact-container {
      width: 100%;
      max-width: 1090px;
      margin-top: 40px;
  
      &.--padding {
        padding: 0 40px;
      }
  
      @include mobile {
        margin-top: 20px;
  
        &.--padding {
          padding: 0 20px;
        }
      }
     
      .contact-scontainer {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;
  
        @include mobile {
          margin-bottom: 20px;
        }
  
        &:last-child {
          margin-bottom: 0px;
        }
  
        &.-no-padding {
          padding: 0;
        }
        &.-information {
          width: 100%;
        }
      }
    }
  
    ._contact_container {
      padding: 20px;
      @include tablette {
        padding: 0px;
      }
      a{
        text-decoration: none;

      }
  
      // ._contact_mb_title {
      //   display: none;
      //   @include fs(14);
      //   font-weight: $semibold;
      //   color: $colorTextBlue;
      //   // margin-bottom: 15px;
  
      //   @include tablette {
      //     display: block;
      //   }
      // }
  
      ._contact_header {
        display: grid;
        grid-template-columns: 10px 2fr 2fr 4fr 4fr 3fr 4fr 10px;
        background-color: #fafafb;
        border-radius: 10px;
        padding: 7px;
  
        @include tablette {
          display: none;
        }
  
        span {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          @include fs(11);
          font-weight: $semibold;
          color: $colorTextBlue;
        }
      }
  
      ._contact_list {
        padding: 20px 0;
        a{
          text-decoration: none;
        }
  
        @include tablette {
          padding: 0px;
        }
  
        ._contact {
          display: grid;
          grid-template-columns: 10px 2fr 2fr 4fr 4fr 3fr 4fr 10px;
          border-radius: 5px;
          padding: 7px;
  
          transition: $transition;
  
          @include tablette {
            background-color: #fff;
            margin-top: 15px;
            border-radius: 10px;
            display: block;
            padding: 20px;
          }
          &:hover {
            box-shadow: 2px 2px 10px #ccc;
            transform: scale(1.01);
          }
  
          .-succes {
            color: $colorSuccess;
          }
          .-failed {
            color: $colorError;
          }
          .-waiting {
            color: $colorWaiting;
          }
  
          div {
            @include fs(12);
            padding: 10px;
            display: flex;
            align-items: center;
            color: #000;
  
            &::before {
              content: attr(data-label);
              margin-right: 5px;
              color: #a5a5a5;
              display: none;
  
              @include tablette {
                display: block;
              }
            }
  
            @include tablette {
              @include fs(14);
              padding: 0px;
              margin-bottom: 10px;
            }
          }
  
          ._picto {
            justify-content: center;
  
            @include tablette {
              display: none;
            }
  
            a {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              @include fs(14);
              font-weight: $semibold;
              color: #fff;
              position: relative;
              overflow: hidden;
              box-shadow: 1px 1px 5px #ccc;
              text-decoration: none;
  
              transition: $transition;
              // &:hover {
              //   box-shadow: 5px 5px 5px #CCC;
              //   transform: scale(1.1);
              // }
  
              // img {
              //   position:absolute;
              //   top:0;left:0;
              //   width: 100%;
              //   height: 100%;
              //   object-fit: cover;
              //   font-family: 'object-fit: contain;';
              // }
            }
          }
  
          ._email {
            word-break: break-all;
          }
  
          ._icon {
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            
            
            @include tablette {
              display: block;
              text-decoration: underline;
            }
  
            &:hover {
              p {
                color: $colorPrimaryDark;
              }
              svg {
                background-color: $colorGrey;
              }
            }
  
            p {
              display: none;
              @include fs(14);
              color: $colorPrimary;
              transition: $transition;
  
              @include tablette {
                display: block;
              }
            }
  
            svg {
              width: 40px;
              height: 40px;
              padding: 13px;
              border-radius: 50%;
  
              transition: $transition;
  
              @include tablette {
                display: none;
              }
            }
          }
  
          ._mb_hidden {
            @include tablette {
              display: none;
            }
          }
        }
      }
    }
    //* css Prime Apoort contact bloc
    .contact-container_prime {
      width: 100%;
      max-width: 1090px;
      margin-top: 40px;
  
      &.--padding {
        padding: 0 40px;
      }
  
      @include mobile {
        margin-top: 20px;
  
        &.--padding {
          padding: 0 20px;
        }
      }
     
      .contact-scontainer_prime {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;
  
        @include mobile {
          margin-bottom: 20px;
        }
  
        &:last-child {
          margin-bottom: 0px;
        }
  
        &.-no-padding {
          padding: 0;
        }
        &.-information {
          width: 100%;
        }
      }
    }
  
    ._contact_container_prime {
      padding: 20px;
      @include tablette {
        padding: 0px;
      }
  
      // ._contact_mb_title {
      //   display: none;
      //   @include fs(14);
      //   font-weight: $semibold;
      //   color: $colorTextBlue;
      //   // margin-bottom: 15px;
  
      //   @include tablette {
      //     display: block;
      //   }
      // }
  
    }
    .radio-container{
      
      display: flex;
      align-items: center;
      gap: 10px;
      height: 40px;
      @include mobile{
        flex-direction: column;
        align-items: flex-start;
        display: flex;
        gap: 10px;
        height: 100%;

      }
      .radio-input{
        align-items: center;
        @include mobile{
          align-items: center;
        }
        input{
          margin: 10px 5px 10px 10px; 
          padding: 0;
          @include mobile{
            margin: 10px 5px 10px 10px; 
            padding: 0;
          }
        }
  
    }

      
      
    }

    .-add-contact-button{
     display: flex;
      justify-content: center;
      max-width:1090px;
      .-add-contact{
        width: 30%;
        @include mobile{
          width: 100%;
        }
        
      }


    }
}
  }
  