.information-container{
  background-color: #fff;
  max-width:1090px;
  border-radius:$borderRadiusBar;
  border: solid 1px #E2E2EA ;
  @include fs(13);

  &.-info-invest{
    margin-bottom: 20px;
  }
 
  .-title{
    border-bottom: 1px solid #E2E2EA;
    padding:20px;
  }
  .-information{
    padding:20px;
    h1{
      font-weight: 500;
      font-size: 36px ;
    
    }
    .btn{
      margin-top:20px;
      button{
       border-radius:$borderRadiusButton ;
      }
    }
    
  }
}
.-apport{
  width: 1060px;
  @include laptop{
    width: auto,
  }
}
.-pay{
  width: 990px;
  @include laptop {
    width : 90%;
    @include tablette {
      width : 100%;

      @include minTablet {
        width : 100%;

        @include mobile {
          width : 100%;

        }

      }
    }
  }
  
}

.container-info{
  margin-bottom: 10px;
}
.container-invst {
    @include fs(13);
    margin-top: 10px;
    max-width: 990px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    // @include laptop{
    //   grid-template-columns:  1fr ;
    @include tablette {
      grid-template-columns:  1fr ;
      // }
    }
    .card {

      background-color: #fff;
      border-radius: $borderRadiusBar;
      border: solid 1px #E2E2EA ;
      

      .card-header{
        border-bottom: solid 0.5px #E2E2EA;
          padding: 20px  ;

          font-weight: 600;
          // label{
          // font-weight: 600;
          //  color:$colorText
          // }

      }
      .card-body{
        padding:20px;
        .-fond_image{
          @include laptop{
           img{
            width:100%
           }
            @include tablette{
              img{
                width:100%
               }
            }
          }

        }
       
        .-fond_description{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top:20px;
        }
        .-payement_card ,.-payement_vir{
          .input{
            margin-top:12px;
          }
          .btn{
            margin-top: 20px;
            button{
              border-radius: $borderRadiusButton;
            }
          }
          .-sub_payement{
            display: flex;
            justify-content: center;
            padding-top:5px;
          }
        }
        .-fond_sub_desc{
          p{
            display:flex;
            flex-direction: column;
            margin-bottom: 10px;
            span{
              margin-top:2px;
              font-weight: 600;
            }
          }
        }
        .-fond_footer{
          margin-top:10px;
          .btn{
            margin-top: 20px;
            button{
              border-radius: $borderRadiusButton;
            }
          }
        }
        .-detail{
          margin-top: 20px;
          text-align: center;
          
          a{
            color: $colorPrimary;
          }
        }
        h2{
          margin-bottom: 20px;
        }
      }
    }
}
.-methods{
  margin-top:20px;
  margin-bottom:20px
}

