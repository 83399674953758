.layout-profile-container {
  padding: 20px 40px;
  //  perspective: 800px; //! pour determiner l'element englobant du footer

  @include mobile {
    padding: 20px;
  }
  //* css commmision bloc

  .affaire-container {
    width: 100%;
    max-width: 1090px;
    margin-top: 40px;

    &.--padding {
      padding: 0 40px;
    }

    @include mobile {
      margin-top: 20px;

      &.--padding {
        padding: 0 20px;
      }
    }
   
    .affaire-scontainer {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      &.-no-padding {
        padding: 0;
      }
      &.-information {
        width: 100%;
      }
    }
  }

  ._affaire_container {
    padding: 20px;
    @include tablette {
      padding: 0px;
    }

    // ._affaire_mb_title {
    //   display: none;
    //   @include fs(14);
    //   font-weight: $semibold;
    //   color: $colorTextBlue;
    //   // margin-bottom: 15px;

    //   @include tablette {
    //     display: block;
    //   }
    // }

    ._affaire_header {
      display: grid;
      grid-template-columns: 60px 2fr 2fr 3fr 4fr 2fr 2fr 60px;
      background-color: #fafafb;
      border-radius: 10px;
      padding: 7px;

      @include tablette {
        display: none;
      }

      span {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        @include fs(11);
        font-weight: $semibold;
        color: $colorTextBlue;
      }
    }

    ._affaire_list {
      padding: 20px 0;

      @include tablette {
        padding: 0px;
      }

      ._affaire {
        display: grid;
        grid-template-columns: 60px 2fr 2fr 3fr 4fr 2fr 2fr 60px;
        border-radius: 5px;
        padding: 7px;

        transition: $transition;

        @include tablette {
          background-color: #fff;
          margin-top: 15px;
          border-radius: 10px;
          display: block;
          padding: 20px;
        }
        &:hover {
          box-shadow: 2px 2px 10px #ccc;
          transform: scale(1.01);
        }

        .-succes {
          color: $colorSuccess;
        }
        .-failed {
          color: $colorError;
        }
        .-waiting {
          color: $colorWaiting;
        }

        div {
          @include fs(12);
          padding: 10px;
          display: flex;
          align-items: center;
          color: #000;

          &::before {
            content: attr(data-label);
            margin-right: 5px;
            color: #a5a5a5;
            display: none;

            @include tablette {
              display: block;
            }
          }

          @include tablette {
            @include fs(14);
            padding: 0px;
            margin-bottom: 10px;
          }
        }

        ._picto {
          justify-content: center;

          @include tablette {
            display: none;
          }

          a {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include fs(14);
            font-weight: $semibold;
            color: #fff;
            position: relative;
            overflow: hidden;
            box-shadow: 1px 1px 5px #ccc;
            text-decoration: none;

            transition: $transition;
            // &:hover {
            //   box-shadow: 5px 5px 5px #CCC;
            //   transform: scale(1.1);
            // }

            // img {
            //   position:absolute;
            //   top:0;left:0;
            //   width: 100%;
            //   height: 100%;
            //   object-fit: cover;
            //   font-family: 'object-fit: contain;';
            // }
          }
        }

        ._email {
          word-break: break-all;
        }

        ._icon {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          @include tablette {
            display: block;
            text-decoration: underline;
          }

          &:hover {
            p {
              color: $colorPrimaryDark;
            }
            svg {
              background-color: $colorGrey;
            }
          }

          p {
            display: none;
            @include fs(14);
            color: $colorPrimary;
            transition: $transition;

            @include tablette {
              display: block;
            }
          }

          svg {
            width: 40px;
            height: 40px;
            padding: 13px;
            border-radius: 50%;

            transition: $transition;

            @include tablette {
              display: none;
            }
          }
        }

        ._mb_hidden {
          @include tablette {
            display: none;
          }
        }
      }
    }
  }
  //* css Prime Apoort affaire bloc
  .affaire-container_prime {
    width: 100%;
    max-width: 1090px;
    margin-top: 40px;

    &.--padding {
      padding: 0 40px;
    }

    @include mobile {
      margin-top: 20px;

      &.--padding {
        padding: 0 20px;
      }
    }
   
    .affaire-scontainer_prime {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      &.-no-padding {
        padding: 0;
      }
      &.-information {
        width: 100%;
      }
    }
  }

  ._affaire_container_prime {
    padding: 20px;
    @include tablette {
      padding: 0px;
    }

    // ._affaire_mb_title {
    //   display: none;
    //   @include fs(14);
    //   font-weight: $semibold;
    //   color: $colorTextBlue;
    //   // margin-bottom: 15px;

    //   @include tablette {
    //     display: block;
    //   }
    // }

    ._affaire_header_prime {
      display: grid;
      grid-template-columns: 60px 3fr 3fr 3fr 3fr 4fr 60px;
      background-color: #fafafb;
      border-radius: 10px;
      padding: 7px;

      @include tablette {
        display: none;
      }

      span {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        @include fs(11);
        font-weight: $semibold;
        color: $colorTextBlue;
      }
    }

    ._affaire_list_prime {
      padding: 20px 0;

      @include tablette {
        padding: 0px;
      }

      ._affaire_prime {
        display: grid;
        grid-template-columns: 60px 3fr 3fr 3fr 3fr 4fr 60px;
        border-radius: 5px;
        padding: 7px;

        transition: $transition;

        @include tablette {
          background-color: #fff;
          margin-top: 15px;
          border-radius: 10px;
          display: block;
          padding: 20px;
        }
        &:hover {
          box-shadow: 2px 2px 10px #ccc;
          transform: scale(1.01);
        }

        .-succes {
          color: $colorSuccess;
        }
        .-failed {
          color: $colorError;
        }
        .-waiting {
          color: $colorWaiting;
        }

        div {
          @include fs(12);
          padding: 10px;
          display: flex;
          align-items: center;
          color: #000;

          &::before {
            content: attr(data-label);
            margin-right: 5px;
            color: #a5a5a5;
            display: none;

            @include tablette {
              display: block;
            }
          }

          @include tablette {
            @include fs(14);
            padding: 0px;
            margin-bottom: 10px;
          }
        }

        ._picto {
          justify-content: center;

          @include tablette {
            display: none;
          }

          a {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include fs(14);
            font-weight: $semibold;
            color: #fff;
            position: relative;
            overflow: hidden;
            box-shadow: 1px 1px 5px #ccc;
            text-decoration: none;

            transition: $transition;
            // &:hover {
            //   box-shadow: 5px 5px 5px #CCC;
            //   transform: scale(1.1);
            // }

            // img {
            //   position:absolute;
            //   top:0;left:0;
            //   width: 100%;
            //   height: 100%;
            //   object-fit: cover;
            //   font-family: 'object-fit: contain;';
            // }
          }
        }

        ._email {
          word-break: break-all;
        }

        ._icon {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          @include tablette {
            display: block;
            text-decoration: underline;
          }

          &:hover {
            p {
              color: $colorPrimaryDark;
            }
            svg {
              background-color: $colorGrey;
            }
          }

          p {
            display: none;
            @include fs(14);
            color: $colorPrimary;
            transition: $transition;

            @include tablette {
              display: block;
            }
          }

          svg {
            width: 40px;
            height: 40px;
            padding: 13px;
            border-radius: 50%;

            transition: $transition;

            @include tablette {
              display: none;
            }
          }
        }

        ._mb_hidden {
          @include tablette {
            display: none;
          }
        }
      }
    }
  }
}
