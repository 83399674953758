.fiscality-form-container {

  padding: 20px;

  .-form {

    .-input-container {

      display: flex;
      margin-bottom: 30px;

      .-label {

        .-input {display: none}

        .-button-container {
          position: relative;
          display: flex;
          
          button {
            height: 45px;
            width: 180px;
            border: 1px solid $colorPrimary;
            background: none;
            border-radius: 3px;
            color: $colorPrimary;
          }

          div {
            @include fs(10);
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateY(110%);
            color: #a2a2a2;
          }
        }
      
      }
      .-indication {
        width: 100%;
        padding-left: 10px;
        @include fs(14);
        display: flex;
        align-items: center;
      }

    }

  }
}