.feedback-container {
  background-color: #000;
  position: fixed;
  bottom: 10px;
  right: -100%;
  width: calc(100% - 20px);
  max-width: 500px;
  padding: 15px;
  border-radius: 5px;
  transition: $transition;
  z-index: 1;

  h2 {
    @include fs(14);
    position: relative;
    padding-left: 12px;
    color: $colorSuccess;
    
    &::after {
      content: "";
      height: 5px;
      width: 5px;
      background-color: $colorSuccess;
      position: absolute;
      top: 8px;
      left: 0px;
      border-radius: 50%;
    }
  }

  &.-error {
    h2 {
      color: $colorError;
      &::after {
        background-color: $colorError;
      }
    }
  }

  &.-warning {
    h2 {
      color: $colorInfo;
      &::after {
        background-color: $colorInfo;
      }
    }
  }

  p {
    margin-bottom: 5px;
    @include fs(14);
    color: #FFF;
  }

  ul {
    margin-bottom: 5px;

    li {
      @include fs(14);
      color: #FFF;
      position: relative;
      padding-left: 12px;
      margin-bottom: 5px;

      &::after {
        content: "";
        height: 3px;
        width: 3px;
        background-color: #FFF;
        position: absolute;
        top: 9px;
        left: 0px;
        border-radius: 50%;
      }
    }
  }

  &.-active {
    right: 10px;
  }
}