
.-bien-container{
  .-subtitle{
    h3{
      margin-top:10px;
    }
  }
  .container-prjcts {
  @include fs(13);
  margin-top: 10px;
  max-width: 990px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  // @include laptop{
  //   grid-template-columns:  1fr ;
  @include tablette {
    grid-template-columns:  1fr ;
    // }
  }
  .card {

    background-color: #fff;
    border-radius: $borderRadiusBar;
    border: solid 1px #E2E2EA ;
    

    .card-header{
      border-bottom: solid 0.5px #E2E2EA;
        padding: 20px  ;

        font-weight: 600;
        // label{
        // font-weight: 600;
        //  color:$colorText
        // }

    }
    .card-body{
      padding:20px;
      .status{
        padding: 4px;
        
        border-radius: 6px;
        background:rgba(#FF974A, .3) ;
        @include fs(11)

      }
      .active_status{
        padding: 4px;
        border-radius: 6px;
        background:rgba(#3DD598, .3);
        
        @include fs(11)
      }
        h2{
          margin-top:10px;
          margin-bottom: 20px;
          @include fs(35);
          font-weight:normal;
        }
      .-bien_image{
        position: relative;

        .img_cont{
          @include img-cover-ct;
          img, picture{
            border-radius: 20px;
          }
        }

        // img{
        //   border-radius: 5%;
        //   width:100%;
        //   max-height:205px ;
        // }
      
        // @include laptop{
        //  img{
        //   border-radius: 5%;
        //   width:100%;
        //   max-height:205px ;
        //  }
        //   @include tablette{
        //     img{
        //       width:100%
        //      }
        //   }
        // }

      }
      .left-corner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: absolute;
        left:0;
        bottom:0;
        margin-left: 14px;
        margin-bottom: 14px;
        // padding: 5px;
        background-color: #fff;
        border-radius: 5px;
        min-width: 170px;
        height: 60px ;
        align-items: center;
       
        .percent{
          display: block;
          @include fs(33);
          font-weight: $semibold;
          color: $colorPrimary;

          height: 40px;
          
        }
        .-interest{
          @include fs(13);
          
        }
        
      }
     
      .-bien_description{
      display:grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top:20px;
      }
      .-payement_card ,.-payement_vir{
        .input{
          margin-top:12px;
        }
        .btn{
          margin-top: 20px;
          button{
            border-radius: $borderRadiusButton;
          }
        }
        .-sub_payement{
          display: flex;
          justify-content: center;
          padding-top:5px;
        }
      }
      .-bien_sub_desc{
        p{
          display:flex;
          flex-direction: column;
          margin-bottom: 10px;
          span{
            margin-top:2px;
            font-weight: 600;
          }
        }
      }
      .-bien_footer{
        margin-top:10px;
        .btn{
          margin-top: 20px;
          button{
            border-radius: $borderRadiusButton;
          }
        }
      }
      .-detail{
        margin-top: 20px;
        text-align: center;
        
        a{
          color: $colorPrimary;
        }
      }
     
    }
  }
}
}