.btn {

  button {

    width: 100%;
    height: 50px;
    border: none;
    border-radius: $borderRadiusButton;
    background-color: $colorPrimary;
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
    transition: $transition;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &:hover {
      background-color: $colorPrimaryDark;

      svg {
        transform: translate(5px, -50%);
      }
    }

    .dual-spinner {
      display: inline-block;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:after {
        content: " ";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
        margin: 0 auto;
      }

    }

    svg {
      font-style: normal;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      transition: $transition;
    }
  }

  &.-medium {
    button {
      max-width: 400px;
      border-radius: 20px;
    }}
  &.-small {
    button {
      max-width: 320px;
      border-radius: 30px;
    }
  }
  &.-tiny {
    button {
      max-width: 250px;
      border-radius: 30px;
    }
  }
  &.-normal{
    button {
      max-width: 479px;
      border-radius: 25px;
    }
  }

  &.-flex-center {
    display: flex;
    justify-content: center;
  }

  &.-flex-right {
    display: flex;
    justify-content: flex-end;
  }

  &.-left {
    button {
      svg {
        right: inherit;
        left: 10px;
        transform: translate(0, -50%) rotate(180deg);
      }

      &:hover {
        svg {
          transform: translate(-5px, -50%) rotate(180deg);
        }
      }
    }
  }

  &.-grey {
    button {
      background-color: $colorGrey;
      color: $colorPrimary;

      svg {
        fill: $colorPrimary;
      }

      &:hover {
        background-color: $colorGreyDark;
      }
    }
  }

  &.-red {
    button {
      background-color: $colorSecondary;

      &:hover {
        background-color: $colorSecondaryDark;
      }
    }
  }

  &.-disable {

    button {
      cursor: default;
      background-color: #e5e5e5;
      color: #ababab;
    }

    svg {
      display: none;
    }
  }

  &.-sub {
    margin-right: 10px;

    button {
      min-width: 80px;
      max-height: 35px;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}