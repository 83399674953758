.navbar {

  width: 100%;
  min-width: 280px;
  max-width: 280px;
  background-color: #fff;
  border-right: 1px solid rgb(225, 225, 225);
  overflow-y: auto;
  transition: $transition;

  @include tablette {
    min-width: 200px;
    max-width: 200px;
  }

  @include mobile {
    position: absolute;
    left: 0; top: 0;
    height: 100%;
    max-width: inherit;
    border-right: none;
    z-index: 1;

    transform: translateX(-100%);

    &.-active {
      transform: translateX(0);
    }

  }

  .-header {

    padding: 10px 20px;

    @include mobile {
      display: flex;
      justify-content: space-between;
      border: 1px solid #E2E2EA;
    }

    .-logoNav {
      text-align: center;
      img {
        height: 70px;
      }
    }

    .-icon {
      border: none;
      background-color: transparent;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: none;
      
      @include mobile {
        display: flex;
      }
    }

  }


  .-links {

    margin-top: 20px;
    
    .-link {
      width: 100%;
      height: 40px;
      margin: 10px 0;
      padding-left: 20px;
      padding-right: 10px;
      display: flex;
      text-decoration: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0; top: 0;
        height: 100%;
        background-color: $colorPrimary;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        width: 0px;
        transition: $transition;
      }

      .-icon {
        width: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        transition: $transition;
        height: 100%;

        svg:nth-child(2) {
          display: none;
        }

        @include tablette {
          display: none;
        }
      }

      .-label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        transition: $transition;
        color: #171725;
        @include fs(13);
      }

      .-arrow {
        width: 40px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition;

        svg:nth-child(2) {
          display: none;
        }

        @include tablette {
          width: 20px;
          min-width: 20px;
        }
      }

      &.-active,
      &:hover {

        &::before {
          width: 5px;
        }

        .-icon {
          svg:nth-child(1) {
            display: none;
          }
          svg:nth-child(2) {
            display: block;
          }
        }

        .-label {
          color: $colorPrimary;
        }

        .-arrow {
          svg:nth-child(1) {
            display: none;
          }
          svg:nth-child(2) {
            display: block;
          }
        }

      }

      &.-button {

        .-label {
          color: #171725;
        }

        &:hover {

          &::before {
            width: 0;
          }
          
          .-icon {
            svg:nth-child(1) {
              display: block;
            }
          }

        }
      }

    }

  }

  .-title {
    padding-left: 20px;
    margin-top: 40px;
    @include fs(14);
    color: #92929D;
  }
  
}