.form-section-title {
  margin-bottom: 30px;
  border-bottom: 1px solid $colorPrimaryLight;

  .-texte {
    text-transform: uppercase;
    @include fs(13);
    margin-bottom: 10px;
    color: $colorTextBlue;
    font-weight: $semibold;
  }

  &.-simple {
    border-bottom: 1px solid $colorGrey;
  }

  &.-simple-2 {
    padding: 20px;
    padding-top: 25px;
    border-bottom-color: #E2E2EA;
    margin-bottom: 0;
    .-texte {
      @include fs(14);
      text-transform: inherit;
      margin-bottom: 0;
      color: #171725;
    }
  }
}