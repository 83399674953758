.infos-bar {
  display: flex;
  padding: 15px;
  border-radius: $borderInfoBox;
  background-color: rgba($colorSuccess, .14);
  margin-bottom: 15px;

  @include mobile {
    display: block;
  }

  .-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include mobile {
      display: block;
      margin-bottom: 5px;
    }

    i {
      background-color: #FFF;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      svg {
        width: 70%;
      }
    }

  }

  .-text {
    color: $colorText;
    @include fs(14);
    line-height: 24px;
    margin-left: 15px;
    font-weight: $regular;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @include mobile {
      margin-left: 0px;
    }
  }
  .-textLink {
    color: $colorText;
    @include fs(14);
    line-height: 24px;
    margin-left: 15px;
    font-weight: $regular;
    width: 100%;
    @include mobile {
      margin-left: 0px;
    }
  }

  .-liste {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    li {
      margin-left: 30px;
      color: $colorText;
      font-size: 14px;
      margin-bottom: 5px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        background-color: $colorText;
        width: 5px; height: 5px;
        top: 8px; left: -15px;
        border-radius: 50%;
      }

      @include tablette() {
        font-size: 12px;
      }

      @include mobile() {
        font-size: 10px;
      }
    }
  }

  &.-info {
    background-color: rgba($colorInfo, .14);
  }

  &.-error {
    background-color: rgba($colorError, $alpha: .14) ;
  }
}