.form-user-contacts {
  display: flex;
  padding: 0;
  margin-bottom: 30px;
  flex-wrap: wrap;

  .-contact {
    margin: 0;
    display: flex;
    overflow: hidden;
    margin-right: 10px;

    .-btn {
      border: none;
      height: 30px;
      background: none;
      cursor: pointer;
      @include fs(13);
      border-radius: 3px;
    }

    .-btn-edit {
      padding: 0 10px;
      color: $colorTextBlue;
      border: 1px solid $colorGreyDark;
      // border-right: none;
      background-color: $colorInputBackground;
      // border-top-right-radius: 0;
      // border-bottom-right-radius: 0;
    }

    .-btn-delete {
      width: 30px;
      font-size: 10px;
      font-weight: bold;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      color: #FFF;
      background-color: $colorError;
    }

  }
}