.form-container {
  @include fs(13);

  .card {
    width: 45%;
    background-color: #ffffff;

    border-radius: 20px;

    .card-header {
      font-weight: $semibold;
      padding: 20px;
      border-bottom: solid 1px #e2e2ea;
    }
    .card-body {
      padding: 20px;

      .-info {
        margin-bottom: 20px;
      }

      .input {
        margin-bottom: 20px;
        input,.-select-wrapper{
        border: 1px solid #c3d1df ;
        height: 40px;
      }
     
      }

      .-checkbox {
        padding-bottom: 20px;
        border-bottom: solid 1px #e2e2ea;
        margin-bottom: 20px;

        .-fileupload {
          display: flex;
          flex-direction: column;
          padding: 10px 0 0 30px;

          span{
            @include fs(11);
            font-style: italic;
          }
        }
      }
      .-conditions {
        padding: 10px 0 0 30px;
      }
      .-indication{
        display: block;
        @include fs(14);
        font-weight: $semibold;
         margin-bottom: 20px;
         font-style: italic;
      }
    }
  }
}
