.login-view-container {
  height: 100vh;
  display: flex;
  overflow: hidden;
  @include mobile {
    overflow: auto;
  }  

  .-infos {
    padding: 60px 120px;
    width: 100%;
    position: relative;

    @include tablette{display: none}
 
    .-logo {

      p {
        @include fs(13);
        color: $colorPrimary;
      }
  
    }
  
    .-title {
      margin-top: 15%;
      position: relative;
      z-index: 1;
      
      h1 {
        @include fs(63);
        font-weight: $semibold;
        line-height: 64px;
        color: $colorText;
        max-width: 500px;
      }
    }
  
    .-register {
      margin-top: 7%;
      position: relative;
      z-index: 1;
  
      p {
        @include fs(18);
        color: $colorText;
      }
      .-smallText{
        @include fs(12);
        color: $colorText;
        margin-top: 10px;
        width: 50%;
      }
      .-subtext{
        margin-top:20px;
        text-decoration:underline;
        a{
          @include fs(16);
          font-weight: normal
        }
      }
  
      a {
        @include fs(18);
        color: $colorPrimary;
        font-weight: $bold;
        text-decoration:underline;
        transition: $transition;
  
        &:hover {
          color: $colorPrimaryDark;
        }
  
      }
  
    }

    .-illustration {
      position: absolute;
      right: 5%;
      bottom: -8%;
      height: 55%;
    }

  }

  .-form {
    overflow: auto;
    background-image: url(../../assets/img/bg-login-form.jpg);
    background-position: center;
    background-size: cover;
    min-width: 500px;

    padding: 60px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    @include tablette {
      min-width: inherit;
      width: 100%;
      padding: 40px;
    }

    @include mobile {
      display: block;
      padding: 20px 20px 60px;
    }

    .-logo {
      display: none;
      @include tablette {
        display: block;}
        p {
          @include fs(13);
          color: $colorPrimary;
        }
    }
  
    .-sup-title {
      margin-top: 5%;
      display: none;
      @include tablette {
        display: block
      }

      h1 {
        @include fs(63);
        font-weight: $semibold;
        color: $colorTextWhite;
        max-width: 500px;
        margin: 5% 0;
        @include tablette {
          @include fs(30)
        }
      }
    }
  
    .-register {
      margin-top: 10%;
      position: relative;
      z-index: 1;
      display: none;
      @include tablette {display: block}
  
      p {
        @include fs(18);
        color: $colorTextWhite;
      }
      .-smallText{
        @include fs(13);
        color: $colorTextWhite;
        margin-top: 10px;
        width: 100%;
        
      }

      .-subtext{
        margin-top:20px;
        // margin-bottom:10px;
        text-decoration:underline;
        a{
          @include fs(16);
          font-weight: normal
        }
        
      }
      
  
      a {
        @include fs(18);
        color: $colorTextWhite;
        font-weight: $bold;
        text-decoration:underline;
        transition: $transition;
      }
  
    }


  }


}


//   @include desktop() {background-color: orange;}
//   @include laptop() {background-color: red;}
//   @include tablette() {background-color: yellow;}
//   @include mobile() {background-color: blue;}