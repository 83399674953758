$bgCirclesColor : #FFF;
$brCircleColor: #D4E0F2;

.stapper-head {
  margin: 60px 0;
  padding-bottom: 40px;
  position: relative;
  display: flex;

  li {
    position: relative;
    width: 100%;

    &:last-child {
      width: inherit;

      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: $brCircleColor;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      width: 0%;
      height: 1px;
      background-color: $colorPrimary;
      transition: $transition;
    }

    .cyrcle {
      position: relative;
      display: inline-block;
      height: 35px;
      width: 35px;
      line-height: 35px;
      font-size: 16px;
      font-weight: $semibold;
      text-align: center;
      color: $colorPrimary;
      background-color: $bgCirclesColor;
      border: 1px solid $brCircleColor;
      border-radius: 50%;
      cursor: pointer;
      transition: $transition;
      z-index: 1;
    }

    .label {
      position: absolute;
      left: 0; bottom: 0;
      color: $colorText;
      text-align: center;
      max-width: 90px;
      min-height: 100%;
      transform: translate(-30%, 120%);
      @include fs(13);
      @include mobile {@include fs(11)};
    }

    &.-actif {

      .cyrcle {
        background-color: $colorPrimary;
        border-color: $colorPrimary;
        color: #FFF;
      }

      &::after {
        width: 100%;
      }

    }

  }



}