._logoutBtn {
  margin-top: 15px;
  width: 40%;
  position: relative;
  left: 50%;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  transition: all linear 0.28s;
  border: none;
  border-bottom: 1px solid $colorDashboardBackground;
  background-color: $colorDashboardBackground;

  &:hover {
    border-bottom: 1px solid $colorPrimary;
    transition: all linear 0.28s;
    color: $colorPrimary;
    cursor: pointer;
  }
}