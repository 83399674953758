.layout-profile-container{
  .-subtitle{
    margin: 50px 0 10px;
  }
}

.container-partners {
  @include fs(13);
  margin-top: 10px;
  max-width: 990px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  // @include laptop{
  //   grid-template-columns:  1fr ;
  @include tablette {
    grid-template-columns: 1fr;
  }
  a{
    text-decoration: none;
    color: black;
  }
  .card {
    padding: 10px;
    background-color: #fff;
    border-radius: $borderRadiusBar;
    border: solid 1px #E2E2EA ;
    .card-body{
      padding:20px;
      padding-bottom: 0;
      .-bien_image{
        position: relative;
        .img_cont{
          @include img-cover-ct;
          img, picture{
            border-radius: 20px;
            // @include centerer();
          }
        }
      }
      .left-corner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: absolute;
        left:0;
        bottom:0;
        margin-left: 14px;
        margin-bottom: 14px;
        min-width: 130px;
     
        .img_scont{
          @include img-cover-ct;
          img, picture{

            border-radius: 20px;

          }
        }
      }
      .infos{
        padding: 5px;
        display: flex;
        flex-direction: column;
        span{
          margin-bottom: 10px;
          &.-name{
            @include fs(30);
            max-height: 35px;
            font-weight:500
          }
          &.-name:hover{
            text-decoration: underline;
          }
          &.-service{
            @include fs(16);
            max-height: 20px;
            margin-bottom: 15px;
            font-weight:$semibold
          }
          &.-adress{
            @include fs(13);
            margin-bottom: 15px;
          }
         
        }
        .-prestation{
         list-style: none;
         li{

          position: relative;
          display: inline-block;
          margin:2px;
          @include fs(11);
          border-radius: 5px;
          padding: 5px;
          background: rgba($color: #EDF3F4, $alpha: 1.0);
         }
        }
     
      } 
    }     
  }
}

.-about{
  // margin-bottom: 20px;
  max-width: 990px;
  .-aboutcontainer{
    padding: 20px;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .-img-infos{ //!revoir Sass après
      position: relative;
      display: flex;
      justify-content: flex-start;
      @include mobile{
        display: block;
        text-align: center;
      }
      .img_cont{
        width: 35%;
        @include img-cover-ct;
        padding-bottom: 20%;
        img, picture{
          border-radius: 20px;
          @include centerer();
        }
        @include mobile{
          width: 60%;
          padding-bottom: 30%;
          margin: 0 auto;
        }
      }
      .-infos{
        margin-left: 30px;
        @include mobile{
          margin-left: 0;
          margin-top: 15px;
        }
        span{ display: block; }
        .-name{
          @include fs(30);
          // margin-bottom: 5px;
          font-weight:$semibold;
        }
        .-service{
          @include fs(16);
          margin-bottom: 5px;
          font-weight:$semibold
        }
        .-adress{
          @include fs(13);
          // margin-bottom: 15px;
        }
      }
    }
    .-content{
      @include fs(14);
      margin-top: 20px;
      p{
        line-height: 2em;
        blockquote{
          padding: 20px;
        }
      }
    }
    .-presta{
      margin-top: 25px;
      .label{
        @include fs (13);
        margin-bottom: 10px;
      }
      .-prestation{
        margin-top: 10PX;
        list-style: none;
        li{
          position: relative;
          display: inline-block;
          margin:2px;
          @include fs(11);
          border-radius: 5px;
          padding: 5px;
          background: rgba($color: #EDF3F4, $alpha: 1.0);
        }
      }
    }
    
    border-bottom: solid 1px #e2e2ea;
  }
  .-aboutfooter{
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    @include fs (13);
    background: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .-contact{
      display: inline-block;
      span{
        display: block;
        &:nth-child(1){
          margin-bottom: 5px;
        }
        &.-value{
          font-weight: $semibold;
        }
      }
    }
    .-website{
      // min-width:250px;
      padding: 0 20px;
      span{
        display: block;
        &:nth-child(1){
          margin-bottom: 5px;
        }
      }
      a{
        color: $colorPrimary;
        font-weight: $semibold;
      }
      // border-right: 1px solid #E2E2EA;
    }
    .btn{
      flex: 1;
      width: 100%;
      max-width: 300px;
      @include mobile{
        margin-top: 30px;
        // flex-shrink: 3;
      }
    }
    
  }
}

.-portfolio{
  .-slider-cont{
    position: relative;
    width: calc( 100vw - 80px - 280px + 15px );
    max-width: 990px;
    overflow: hidden;
    overflow-x: auto;
    @include tablette{
      width: calc( 100vw - 80px - 200px + 15px );
    }
    @include mobile{
      width: calc( 100vw - 80px + 15px );
    }
  }
  .-sliders{
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 25px;
  }
  .-slide{
    position: relative;
    margin-right: 20px;
    width: 100%;
    // max-width: 540px; // desktop width
    &:last-child{
      margin-right: 0;
    }
    .-img_cont{
      width: 540px;
      height: 360px;
      @include img-cover-ct;
      padding-bottom: 65%;
      @include laptop{
        width: 335px;
      }
      @include mobile{
        width: 335px;
      }
    }
  }
  
  .-nfo{
    padding: 25px;
    background: #fff;
    border-radius: 0 0 20px 20px;
    // overflow: hidden;
    span, a{
      display: block;
      @include fs(14);
    }
    a{
      margin-top: 10px;
      color: $colorPrimary;
      &:after{
        content: ">";
        display: inline-block;
        margin-left: 4px;
        transition: .25s ease-in-out;
      }
      &:hover{
        text-decoration: none;
        &:after{
          transform: translate3d(2px,0,0);
        }
      }
    }
  }
  .-slide img, .-slide picture{
    max-width: 540px;
    display: block;
    border-radius: 20px 20px 0 0;
  }
}

.-services{
  max-width: 990px;
  .-services-container{
    // background: red;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:20px;
    @include tablette{
      grid-template-columns: repeat(2, 1fr);
      gap:30px;
    }

    @include mobile{
      grid-template-columns: repeat(1, 1fr);
    }

    .-service{
      background:#fff;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 20px;

      .-title{
        @include fs (18);
        font-weight: $semibold;
        margin-bottom: 15px;
      }
      .-description{
        @include fs (13);

        margin-bottom: 15px;
      }
      .-price{
        @include fs (18);
        color: $colorPrimary;
        font-weight: $semibold;
        margin-bottom: 10px;
      }
      .-reduction{
        @include fs (11);
        padding: 4px 0px 4px 10px;
        color: $colorPrimary;
        background: rgba($color: #0062FF, $alpha: .14);
        border-radius: 6px;
      }
    }
  }

 
}
.-header-title{
  padding: 20px;
  max-width: 990px;
  border-top-left-radius:20px;
  border-top-right-radius:20px;
  border-bottom: 1px solid #E2E2EA;
  background: #fff;
  .-texte{
    @include fs(13);
  }
  span{
    @include fs(12);
    color:$colorError;
  }
}

.-form{
  background:#fff;
  padding: 20px;
  max-width: 990px;
  border-bottom-left-radius:20px;
  border-bottom-right-radius:20px;


.-lblmsg{
  @include fs (13);
}

 .-lblmsg:after{
  content: "*";
  color:#f44336;
  margin-left: 2px;
 }
 .-textarea{
  max-width: 950px;
  outline: transparent;
  background: #f4f9fe;
  border: none;
  padding: 10px;
  font-family:'Poppins', sans-serif;
  @include fs (12);
  &.-error{
    border: 1px solid $colorError;
  }
 }
 .btn{
  text-align: center;
 }
}