.infos {
  padding: 20px 10px;
  margin: 20px 0;
  border-radius: $borderRadiusInput;

  &.-error {background-color: $colorError;}
  &.-succes {background-color: $colorSuccess;}
  &.-info {background-color: $colorInfo;}
  p {
    color: #FFF;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
  }
}