.form-row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;

  &.-two {
    grid-template-columns: repeat(2, 1fr);

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }

  }

  &.-three {
    grid-template-columns: repeat(3, 1fr);

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }

  }

}

.form-row-radio {
  margin-bottom: 20px;

  p {
    display: block;
    @include fs(13);
    margin-bottom: 10px;
    color: $colorText;
  }

  .-inputs-container {
    display: flex;

    label {
      display: flex;
      margin-right: 20px;
      cursor: pointer;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      i {
        position: relative;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        position: relative;
        border: 2px solid #92929D;
        margin-right: 5px;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
        }
      }

      span {
        height: 100%;
        @include fs(13);
        color: $colorText;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      input {
        display: none;
      }

      &.-checked {
        i::after {
          background-color: $colorPrimary;
        }
      }
    }

  }

  &.-column {
    .-inputs-container {
      display: block;

      label {
        margin-right: 0px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

}

.form-row-checkbox {

  label {

    display: flex;
    cursor: pointer;
    align-items: flex-start;

    i {
      margin-top: 2px;
      min-height: 15px;
      min-width: 15px;
      margin-right: 10px;
      border-radius: 2px;
      border: 1px solid grey;
      position: relative;
      transition: .3s ease-in-out;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 12px;
        height: 12px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
        display: none;
      }

      &:hover {
        border: 1px solid $colorText;
      }
    }

    a {
      color: $colorPrimary;
    }

    span {
      @include fs(13);
      color: $colorTextGrey;
      margin: auto 0;
      transition: .3s ease-in-out;

      &:hover {
        color: $colorText;
      }
    }

    input {
      display: none;
    }

    &.-checked {
      i {
        border-color: $colorSuccess;
        background-color: $colorSuccess;

        &::after {
          display: inherit;
        }
      }
    }
  }

  &.-error label {
    i {
      border-color: $colorError;
      background-color: $colorGrey;
    }

    span {
      color: $colorError;
    }
  }

}