.ruban{
  position: fixed;
  bottom:0;
  left:0;
  right:0;
  display:flex;
  justify-content: center;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  background: $colorError;
  z-index: 1;
}
.container-big {

  position: relative;
  background-color: $colorDashboardBackground;
  display: flex;
  width: 100%;
  min-height: 100vh;

  .container-small {
    width: 100%;
    position: relative;
    

    .footer{
      width: 100%;
      padding: 20px 40px;
      text-align:center;
      @include mobile {
        padding: 20px;
      }

      span{
        @include fs(11)
      }

      ul{
        list-style: none;
        li{
          position: relative;
          display: inline-block;
          margin: 0 0px;
          padding: 0 10px;
          @include fs(11);
          &:after{
            content:"|";
            display: inline-block;
            position: absolute;
            top: 2px;
            right: 0;
            @include fs(10);
            color: #aaa;
          }
          &:last-child{
            &:after{
              content:none;
            }
          }
          a{
            display:block;
            color:$colorPrimary;
            text-decoration: underline;
            &:hover{
              text-decoration: none;
            }
          }
        }
        &.crn{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          li{
            display: flex;
            align-items: center;
            a, img{
              margin: 0 2px;
            }
            img{
              display: block;
              width: 100%;
              max-width: 16px;
            }
          }
        }
      }
    }
   
  }
 
  
}
.layout-dashboard-container {
  padding: 20px 40px;

  @include mobile {
    padding: 20px;
  }

  // ._listUsersDocumentsContainer{
  //   max-width: 71%;
  // }
}
.-edit{
  .sub-navbar-container{
    max-width: 1090px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .sub-navbar{
      display:none;
    }
    .input{
      display:block;
      width: auto;
      }
  }
}
