.registration-container {

  border-top: 7px solid $colorPrimary;

  .-forms-container {
    padding: 40px 40px;
    max-width: 920px;
    margin: 0 auto;

    .-logo {
      text-align: center;
  
      p {
        @include fs(13);
        color: $colorPrimary;
      }
    }
    .input{
      input{
        border: 1px solid #c3d1df ;
      }
      .-select-wrapper{
        border: 1px solid #c3d1df ;
      }
      &.-error{
        input {
          border: 1px solid $colorError;
        }
      }
    }
  }

}